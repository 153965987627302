class AdminDashboard {
    private lv: LifeVine;
    private page: JQuery;
    private dashboard: JQuery;
    private assignedList: JQuery;
    private availableList: JQuery;
    private view: JQuery;

    constructor(lv: LifeVine) {
        this.lv = lv;
        this.page = jQuery('#page-admin-dashboard');
        this.dashboard = this.page.find('.dashboard');
        this.assignedList = this.dashboard.find('.assigned-bookings');
        this.availableList = this.dashboard.find('.available-bookings');
        this.view = this.page.find('.booking_viewer');

        this.assignedList.on('click', 'li', event => AdminDashboard.openBooking(event));
        this.availableList.on('click', 'li', event => AdminDashboard.openBooking(event));
    }

    private static openBooking(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        let $li = jQuery(event.target).closest('li');
        location.hash = ['#page-bookings', $li.attr('data-client-id'), $li.attr('data-id')].join(':');
    }

    public index(options?: LifeVineBookingsOptions) {
        this.page.attr('data-mode', 'index');
        this.lv.bookings().get()
            .done(bookings => {
                this.fillBookingList(this.availableList, bookings);
            });

        this.lv.bookings().get(null, {status: [2, 3, 4]})
            .done(bookings => {
                this.fillBookingList(this.assignedList, bookings);
            });
    }

    private fillBookingList(list: JQuery, bookings) {
        list.html('');
        jQuery.each(bookings, (idx, booking) => {
            list.append(AdminDashboard.createBookingListItem(booking));
        });
    }

    private static createBookingListItem(booking) {
        let client = booking.client;
        let color = '';
        if (booking.status_text === 'available') {
            color = 'blue lighten-2';
        } else if (booking.status_text === 'pending') {
            color = 'yellow lighten-2';
        } else if (booking.status_text === 'confirmed') {
            color = 'green accent-3';
        }

        let content = '' +
            `<i class="material-icons circle ${color}">event_note</i>` +
            `<span class="title">${booking.start_time}</span>` +
            `<p>${booking.duration_h} hrs with ${booking.dependents} chn for ${client.first_name} ${client.last_name}</p>`;
        return jQuery('<li class="collection-item avatar"></li>')
            .append(content)
            .addClass('status-' + booking.status_text)
            .attr('data-client-id', client.id)
            .attr('data-id', booking.id);
    }
}

window.app.page('page-admin-dashboard', () => {
    return params => {
        if (!window.controllers.adminDashboard) {
            window.controllers.adminDashboard = new AdminDashboard(window.lifeVine);
        }
        window.controllers.adminDashboard.index();
    };
});